import a from './images/acaiSmmoth.jpg';
import s from './images/signatureD.jpg';
import p from './images/pistachioF.jpg';
import o from './images/oreoFF.jpg';
import m from './images/mangoF.jpg';
import uf from './images/ubeFr.jpg';
 
const Frapp= {
    Frap:[
       {
           id:  '1',
           name: 'PISTACHIO ',
           price: '24.15',
           image: p,
           details: 'all prices include 5% vat'
       },
       {
           id:  '2',
           name: 'MANGO',
           price: '25.2',
           image: m,
           details: 'all prices include 5% vat'
       },
       {
           id:  '3',
           name: 'SIGNATURE',
           price: '22.05',
           image: s,
           details: 'all prices include 5% vat'
       },
       {
           id:  '4',
           name: 'OREO FRAPPE ',
           price: '22.05',
           image: o,
           details: 'all prices include 5% vat'
       },
       {
           id:  '5',
           name: 'ACAI SMOOTHIE',
           price: '26.25',
           image: a,
           details: 'all prices include 5% vat'
       },
       {
        id:  '5',
        name: 'UBE FRAPPE',
        price: '25.2',
        image: uf,
        details: 'all prices include 5% vat'
    },
       
   ]
}

export default Frapp;