import hd from './images/hotdog.jpeg';
// import ms from './images/mushroom.jpg';
// import mp from './images/mag.jpg';
// import pp from './images/pepperoni.jpg';
import ff from './images/fries.jpeg';
import hdc from './images/chicken hotdog.jpeg';
const Specials = {
     Special:[
        // {
        //     id:  '16',
        //     name: 'PEPPERONI PIZZA ',
        //     price: '30',
        //     image: pp,
        //     details: ''
        // },
        // {
        //     id:  '17',
        //     name: 'MUSHROOM PIZZA',
        //     price: '31',
        //     image: ms,
        //     details: ''
        // },
        // {
        //     id:  '15',
        //     name: 'MARGARITA PIZZA',
        //     price: '28',
        //     image: mp,
        //     details: ''
        // },
        {
            id:  '12',
            name: 'FRIES',
            price: '15.75',
            image: ff,
            details: 'all prices include 5% vat'
        },

        {
            id:  '13',
            name: 'HOT DOG CHICKEN',
            price: '29.40',
            image: hdc,
            details: 'all prices include 5% vat'
        },
        {
            id:  '14',
            name: 'HOT DOG BEEF',
            price: '29.40',
            image: hd,
            details: 'all prices include 5% vat'
        },
   
        
    
    ]
}

export default Specials;
