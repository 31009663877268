import React from 'react';
import Cream from './Cream';


const Home = () => {
    

  return (
      <div className='home'>
        <Cream />
    </div>
  ) 
}

export default Home;